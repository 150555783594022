export default {
  newTask({ state, commit }, payload) {
    const newItem = {
      project_id: state.project.id,
      project_user_story_id: payload.userStory.id,
      status: 'new',
      is_private: false,
    };

    commit('openTaskForm', {
      item: newItem,
      sprintIndex: payload.sprintIndex,
      userStoryIndex: payload.userStoryIndex,
      index: -1,
    });
  },

  editTask({ commit }, payload) {
    commit('openTaskForm', payload);
  },

  createTask({ commit }, payload) {
    commit('createTask', payload);
  },

  updateTask({ commit }, payload) {
    commit('updateTask', payload);
  },

  updateTaskStatus({ commit }, payload) {
    commit('updateTaskStatus', payload);
  },

  deleteTask({ commit }, payload) {
    commit('deleteTask', payload);
  },
};
