/* eslint-disable no-shadow,no-param-reassign,no-prototype-builtins, object-curly-spacing */
import Vue from 'vue';
import {
  SET_SETTINGS, SET_TOUCH_DEVICE, UPDATE_LOCALE, UPDATE_SETTING,
} from '../mutation-types';

const state = {
  locale: 'lt',
  localeIcon: require('@/assets/locale/lt-flag.png'), // 'require' is used to make v-img work with dynamic src attributes
  isTouchDevice: false,
  sidenavOpen: false,
  showUnassignedUserStories: true,
  settings: {
    showUnassignedUserStories: true,
  },
  openDialogs: [],
};

const getters = {
  settings(state) {
    return state.settings;
  },

  isTouchDevice(state) {
    return state.isTouchDevice;
  },
};

const mutations = {
  [SET_SETTINGS](state, payload) {
    state.sidenavOpen = payload.sidenavOpen === false ? payload.sidenavOpen : true;
    state.locale = payload.locale ? payload.locale : 'lt';
    state.localeIcon = payload.localeIcon ? payload.localeIcon : require('@/assets/locale/lt-flag.png');
    localStorage.setItem('settings', JSON.stringify(state));
  },

  [UPDATE_SETTING](state, payload) {
    if (payload) {
      state[payload.key] = payload.value;
    }
    localStorage.setItem('settings', JSON.stringify(state));
  },

  [UPDATE_LOCALE](state, payload) {
    state.locale = payload.locale;
    state.localeIcon = payload.localeIcon;
    localStorage.setItem('settings', JSON.stringify(state));
  },

  [SET_TOUCH_DEVICE](state) {
    state.isTouchDevice = true;
  },

  updateApplicationSettings(state, payload) {
    if (payload) {
      Vue.set(state.settings, payload.key, payload.value);
    }
    localStorage.setItem('appSettings', JSON.stringify(state.settings));
  },

  storeApplicationSettings(state, payload) {
    const settings = {
      showUnassignedUserStories: payload.hasOwnProperty('showUnassignedUserStories') ? payload.showUnassignedUserStories : true,
    };
    Vue.set(state, 'settings', settings);
    localStorage.setItem('appSettings', JSON.stringify(state.settings));
  },

  setTouchDevice(state) {
    state.isTouchDevice = true;
  },

  openDialog(state, dialog) {
    if (state.openDialogs.indexOf(dialog) === -1) {
      state.openDialogs.push(dialog);
    }
  },

  closeDialog(state, dialog) {
    for (let i = 0; i < state.openDialogs.length; i++) {
      if (state.openDialogs[i] === dialog) {
        state.openDialogs.splice(i, 1);
        break;
      }
    }
  },

  closeLastDialog(state) {
    state.openDialogs.pop();
  },
};

const actions = {
  storeApplicationSettings({commit}, payload) {
    commit('storeApplicationSettings', payload);
  },

  updateApplicationSettings({commit}, payload) {
    commit('updateApplicationSettings', payload);
  },
};

const settings = {
  state,
  getters,
  mutations,
  actions,
};

export default settings;
