import http from './http';

const endpoint = 'api/users';

const userService = {
  model: 'user',

  getPage: (page, params) => http.get(`${endpoint}?page=${page}`, { params }),

  getCurrent: () => http.get(`${endpoint}/current`),
  // TODO remove once intranet API is not longer needed
  // getCurrent: () => http.get('api/user'),

  search: query => http.get(`${endpoint}/find/${query}`),

  create: user => http.post(`${endpoint}`, user),

  update: user => http.put(`${endpoint}/${user.id}`, user),

  delete: user => http.delete(`${endpoint}/${user.id}`),

  toggleStatus: user => http.patch(`${endpoint}/${user.id}/toggle-suspend`),

  resetPassword: user => http.post(`${endpoint}/${user.id}/reset-password`, user),

  changeCurrentUserPassword: payload => http.put(`${endpoint}/current/password`, payload),
};

export default userService;
