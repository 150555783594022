/* eslint-disable import/no-cycle */
import Vue from 'vue';
import VueRouter from 'vue-router';
import {
  authGuard,
  loginGuard,
  permissionsGuard,
  roleGuard,
} from './guards';
import Login from '../views/Login';
import store from '../store/index';
import { TRY_AUTO_LOGIN } from '../store/action-types';
import Home from '../views/Home';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
  },
  {
    path: '/assets/:tab',
    name: 'assets',
    component: () => import('../views/Assets'),
    beforeEnter: permissionsGuard,
    meta: { requiredPermission: ['inventory.items.view'] },
  },
  {
    path: '/assets',
    redirect: '/assets/list',
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    beforeEnter: loginGuard,
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile'),
    beforeEnter: authGuard,
  },
  {
    path: '/check-ins',
    name: 'check-ins',
    component: () => import('../views/CheckIns'),
    beforeEnter: permissionsGuard,
    meta: { requiredPermission: ['check-in.*'] },
  },
  {
    path: '/projects',
    name: 'projects',
    component: () => import('../views/Projects'),
    beforeEnter: permissionsGuard,
    meta: { requiredPermission: ['projects.view.*'] },
  },
  {
    path: '/project/:id',
    name: 'project',
    meta: { requiredPermission: ['projects.view.*'] },
    redirect: '/project/:id/backlog',
    component: () => import('../components/projects/ProjectWrapper'),
    beforeEnter: permissionsGuard,
    children: [
      {
        path: 'backlog',
        name: 'project-backlog',
        meta: { requiredPermission: ['projects.view.*'] },
        component: () => import('../components/projects/project-backlog/ProjectBacklog'),
        beforeEnter: permissionsGuard,
      },
      {
        path: 'team',
        name: 'project-team',
        meta: { requiredPermission: ['projects.view.*'] },
        component: () => import('../components/projects/project-assignees/ProjectAssignees'),
        beforeEnter: permissionsGuard,
      },
      // {
      //   path: 'statistics',
      //   name: 'project-statistics',
      //   meta: { permissions: ['projects.view.*'] },
      //   component: () => import('../components/projects/project-statistics/ProjectStatistics'),
      //   beforeEnter: authGuard,
      // },
    ],
  },
  {
    path: '/time-tracking',
    name: 'time-tracking',
    meta: { requiredPermission: ['projects.view.*'] },
    component: () => import('../components/projects/time-tracking/TimeTrackingWrapper'),
    beforeEnter: permissionsGuard,
  },
  {
    path: '/users',
    name: 'users',
    component: () => import('../views/Users'),
    beforeEnter: roleGuard,
    meta: { allowedRoles: ['admin'] },
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: process.env.VUE_APP_HTML_HISTORY_MODE ? 'history' : '',
  base: process.env.VUE_APP_PUBLIC_PATH,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (to.meta.isPublic) {
    next();
    return;
  }
  if (!store.getters.accessToken) {
    await store.dispatch(TRY_AUTO_LOGIN);
  }
  next();
});

export default router;
