<template>
  <v-img
    :src="require('@/assets/login-bg.jpg')"
    height="100%"
    width="100%"
    style="position: fixed; text-align: center;"
  >
    <v-container fluid class="fill-height">
      <v-row
        align="center"
        justify="center"
        no-gutters
      >
        <v-col
          cols="12"
          sm="8"
          md="6"
          lg="4"
        >
          <v-card class="elevation-12">
            <div class="text-right pr-2 pt-2">
<!--              <LanguageSelector/>-->
            </div>

            <v-img
              :src="require('@/assets/volinta_logo.png')"
              height="60"
              width="300"
              class="ma-auto"
              contain
            />

            <v-form ref="form" @submit.prevent="login">
              <v-card-text>
                <v-text-field
                  v-model="username"
                  :label="$t('username')"
                  prepend-icon="mdi-account"
                  name="username"
                  type="email"
                  autofocus
                />

                <v-text-field
                  v-model="password"
                  :append-icon="isPasswordHidden ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="isPasswordHidden ? 'password' : 'text'"
                  :label="$t('password')"
                  prepend-icon="mdi-lock"
                  name="password"
                  @click:append="isPasswordHidden = !isPasswordHidden"
                />
              </v-card-text>

              <v-card-actions>
                <v-btn
                  :loading="isRequestPending"
                  :disabled="isRequestPending"
                  color="primary"
                  type="submit"
                  block
                >
                  {{ $t('login') }}
                </v-btn>
              </v-card-actions>
            </v-form>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-img>
</template>

<script>
// import LanguageSelector from '../components/LanguageSelector';
import authService from '../api/auth-service';
import userService from '../api/user-service';
import eventBus, { HIDE_LOADER, OPEN_SNACKBAR } from '../util/event-bus';
import { GET_CURRENT_USER, HANDLE_AUTH_DATA } from '../store/action-types';

export default {
  name: 'Login',

  components: {
    // LanguageSelector,
  },

  data() {
    return {
      username: '',
      password: '',
      isPasswordHidden: true,
      isRequestPending: false,
    };
  },

  created() {
    eventBus.$emit(HIDE_LOADER);
  },

  methods: {
    async login() {
      this.isRequestPending = true;
      try {
        const loginRes = await authService.login(this.username, this.password);
        await this.$store.dispatch(HANDLE_AUTH_DATA, loginRes.data);
        const userRes = await userService.getCurrent();
        await this.$store.dispatch(GET_CURRENT_USER, userRes.data);
        await this.$router.replace('/');
      } catch (e) {
        if (e.response?.status === 401) {
          eventBus.$emit(OPEN_SNACKBAR, this.$t('http_errors.401'));
        }
      }
      this.isRequestPending = false;
    },
  },
};
</script>
