/* eslint-disable no-shadow,no-param-reassign,no-mixed-operators,import/no-cycle */
import router from '../../router/index';
import {
  SET_ACCESS_TOKEN,
  SET_AUTO_LOGIN_ATTEMPT,
  SET_IMPERSONATED_USER_ID,
  CLEAR_AUTH_DATA,
  SET_CURRENT_USER,
  CLEAR_IMPERSONATOR, SET_CURRENT_USER_PERMISSIONS,
} from '../mutation-types';
import {
  GET_CURRENT_USER,
  HANDLE_AUTH_DATA,
  IMPERSONATE_USER,
  LOGOUT, REFRESH_TOKEN, STOP_IMPERSONATING,
  TRY_AUTO_LOGIN,
} from '../action-types';
import userService from '../../api/user-service';
import authService from '../../api/auth-service';

const availablePermissions = [
  'inventory.*',
  'inventory.items.*',
  'inventory.items.view',
  'inventory.categories.*',
  'check-in.*',
  'projects.*',
  'projects.view.*',
  'projects.create.*',
];

const availablePermissionsTree = [
  {
    permission: 'inventory.*',
    children: [
      {
        permission: 'inventory.items.*',
        children: [
          {
            permission: 'inventory.items.view',
          },
        ],
      },
      {
        permission: 'inventory.categories.*',
      },
    ],
  },
  {
    permission: 'projects.*',
    children: [
      {
        permission: 'projects.view.*',
      },
      {
        permission: 'projects.create.*',
      },
    ],
  },
  {
    permission: 'check-in.*',
  },
];

const state = {
  accessToken: null,
  impersonatingAs: null,
  currentUser: null,
  currentUserPermissions: [],
  wasAutoLoginAttempted: false,
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },

  permissions(state) {
    return state.currentUserPermissions;
  },

  accessToken(state) {
    return state.accessToken;
  },

  impersonatingAs(state) {
    return state.impersonatingAs;
  },
};

const mutations = {
  [SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },

  [SET_CURRENT_USER](state, currentUser) {
    state.currentUser = currentUser;
    // TODO remove once intranet API is not longer needed
    // state.currentUser = {
    //   ...currentUser?.user,
    //   person: currentUser?.user?.personal_data,
    // };
  },

  [SET_CURRENT_USER_PERMISSIONS](state, permissions) {
    state.currentUserPermissions = permissions;
  },

  [SET_AUTO_LOGIN_ATTEMPT](state, status) {
    state.wasAutoLoginAttempted = status;
  },

  [CLEAR_AUTH_DATA](state) {
    state.accessToken = null;
    state.currentUser = null;
    state.permissions = null;
    state.impersonatingAs = null;
  },

  [SET_IMPERSONATED_USER_ID](state, id) {
    state.impersonatingAs = id;
  },

  [CLEAR_IMPERSONATOR](state, payload) {
    state.currentUser = payload.impersonator;
    state.impersonatingAs = null;
  },
};

const actions = {
  [HANDLE_AUTH_DATA]({ commit }, payload) {
    const now = new Date();
    // refresh access token when there's less than 1/3rd left on its expiry time
    const refreshAt = new Date(now.getTime() + payload.expires_in * 666);
    commit(SET_ACCESS_TOKEN, payload.access_token);
    localStorage.setItem('accessToken', payload.access_token);
    localStorage.setItem('refreshToken', payload.refresh_token);
    localStorage.setItem('tokenRefreshDate', refreshAt.toString());
  },

  async [REFRESH_TOKEN]({ dispatch }, refreshToken) {
    try {
      const response = await authService.refreshToken(refreshToken);
      dispatch(HANDLE_AUTH_DATA, response.data);
    } catch (e) {
      dispatch(LOGOUT);
    }
  },

  async [TRY_AUTO_LOGIN]({ state, commit, dispatch }) {
    if (state.wasAutoLoginAttempted) {
      return;
    }
    commit(SET_AUTO_LOGIN_ATTEMPT, true);

    const refreshToken = localStorage.getItem('refreshToken');
    let refreshAt = localStorage.getItem('tokenRefreshDate');
    if (refreshAt) {
      refreshAt = new Date(refreshAt);
    }
    const now = new Date();
    if (refreshToken && (!refreshAt || now.getTime() >= refreshAt.getTime())) {
      await dispatch(REFRESH_TOKEN, refreshToken);
    } else {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        commit(SET_ACCESS_TOKEN, accessToken);
      } else {
        return;
      }
    }

    const impersonator = JSON.parse(localStorage.getItem('impersonator'));
    if (impersonator) {
      const impersonatingAs = JSON.parse(localStorage.getItem('currentUser'));
      if (impersonatingAs) {
        commit(SET_IMPERSONATED_USER_ID, impersonatingAs.id);
      } else {
        localStorage.removeItem('impersonator');
      }
    }
    await dispatch(GET_CURRENT_USER);
  },

  [LOGOUT]({ commit }) {
    commit(CLEAR_AUTH_DATA);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenRefreshDate');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('impersonator');
    router.replace('/login').catch(() => {});
  },

  async [GET_CURRENT_USER]({ commit, state, dispatch }) {
    let response;
    if (!state.accessToken) {
      return response;
    }
    try {
      response = await userService.getCurrent();
      commit(SET_CURRENT_USER, response.data);
      localStorage.setItem('currentUser', JSON.stringify(response.data));
      const permissions = {};
      if (response.data.role === 'admin') {
        availablePermissions.forEach((p) => {
          permissions[p] = true;
        });
      }

      response.data.permissions.forEach((p) => {
        permissions[p.permission] = true;
      });
      if (permissions['inventory.*']) {
        permissions['inventory.items.*'] = true;
        permissions['inventory.categories.*'] = true;
        // permissions['inventory.requests.*'] = true;
      }
      if (permissions['inventory.items.*']) {
        permissions['inventory.items.view'] = true;
        permissions['inventory.items.edit'] = true;
      }
      if (permissions['inventory.categories.*']) {
        permissions['inventory.categories.view'] = true;
        permissions['inventory.categories.edit'] = true;
      }
      if (permissions['projects.*']) {
        permissions['projects.view.*'] = true;
        permissions['projects.create.*'] = true;
      }
      commit(SET_CURRENT_USER_PERMISSIONS, permissions);
    } catch (e) {
      response = e;
      dispatch(LOGOUT);
    }
    return response;
  },

  async [IMPERSONATE_USER]({ commit, state, dispatch }, id) {
    commit(SET_IMPERSONATED_USER_ID, id);
    localStorage.setItem('impersonator', JSON.stringify(state.currentUser));
    await dispatch(GET_CURRENT_USER);
    await router.replace('/');
  },

  [STOP_IMPERSONATING]({ commit }) {
    const impersonator = JSON.parse(localStorage.getItem('impersonator'));
    commit(CLEAR_IMPERSONATOR, {
      impersonator,
    });
    localStorage.setItem('currentUser', JSON.stringify(impersonator));
    localStorage.removeItem('impersonator');
  },
};

const auth = {
  state,
  getters,
  mutations,
  actions,
};

export default auth;

export { availablePermissions, availablePermissionsTree };
