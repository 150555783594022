// auth
export const SET_ACCESS_TOKEN = 'setAccessToken';
export const SET_CURRENT_USER = 'setCurrentUser';
export const SET_CURRENT_USER_PERMISSIONS = 'setCurrentUserPermissions';
export const SET_AUTO_LOGIN_ATTEMPT = 'setAutoLoginAttempt';
export const CLEAR_AUTH_DATA = 'clearAuthData';
export const SET_IMPERSONATED_USER_ID = 'setImpersonatedUserId';
export const CLEAR_IMPERSONATOR = 'stopImpersonating';

// settings
export const SET_SETTINGS = 'setSettings';
export const UPDATE_SETTING = 'updateSetting';
export const UPDATE_LOCALE = 'updateLocale';
export const SET_TOUCH_DEVICE = 'setTouchDevice';
