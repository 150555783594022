export default {
  newUserStory({ state, commit }, payload) {
    const newItem = {
      project_id: state.project.id,
      sprint_id: payload?.sprint ? payload.sprint.id : null,
      status: 'new',
      estimated_front_end_points: null,
      estimated_back_end_points: null,
      estimated_ux_points: null,
      estimated_design_points: null,
    };

    commit('openUserStoryForm', {
      item: newItem,
      index: -1,
      sprintIndex: payload?.sprintIndex ? payload.sprintIndex : null,
    });
  },

  newBulkInsert({ state, commit }) {
    const newItem = {
      project_id: state.project.id,
      sprint_id: null,
      info: '',
    };

    commit('openBulkInsertForm', {
      item: newItem,
      index: -1,
      sprintIndex: null,
    });
  },

  updateUserStories({ commit }, payload) {
    commit('updateUserStories', payload);
  },

  bulkInsertUserStories({ commit }, userStories) {
    commit('bulkInsertUserStories', userStories);
  },

  editUserStory({ commit }, payload) {
    commit('openUserStoryForm', payload);
  },

  createUserStory({ commit }, payload) {
    commit('createUserStory', payload);
  },

  updateUserStory({ state, commit }, userStory) {
    commit('updateUserStory', userStory);
    if (+state.defaultItem.sprint_id !== +userStory.sprint_id) {
      commit('moveUserStory', userStory);
    }
  },

  updateUserStoryStatus({ state, commit }, userStory) {
    commit('updateUserStoryStatus', userStory);
  },

  addUserStoryAttachments({ commit }, userStory) {
    commit('addUserStoryAttachments', userStory);
  },

  toggleUserStoryStatus({ commit }, payload) {
    commit('toggleUserStoryStatus', payload);
  },

  deleteUserStory({ commit }, payload) {
    commit('deleteUserStory', payload);
  },

  moveUserStory({ commit }, userStory) {
    commit('moveUserStory', userStory);
  },
};
