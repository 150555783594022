import Vue from 'vue';
import http from '../../api/http';
import eventBus from '../../util/event-bus';

function addProjectsModuleDependencies() {
  Vue.prototype.$http = http;
  Vue.prototype.$eventBus = eventBus;
  Vue.prototype.$openFile = (attachment, nameField) => {
    if (!attachment.url) {
      return;
    }
    let fileName;
    if (nameField) {
      fileName = nameField;
    } else {
      fileName = 'file_name';
    }
    this.$http({
      url: attachment.url,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const fileURL = URL.createObjectURL(response.data);
      const windowRef = window.open(fileURL, attachment[fileName]);
      setTimeout(() => {
        if (!windowRef || (windowRef && !windowRef.self)) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', attachment[fileName]);
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }, 500);
    });
  };
}

export default addProjectsModuleDependencies;
