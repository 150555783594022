import { addDays, format } from 'date-fns';
import http from '../../../../api/http';

export default {
  toggleClosedSprints({ state, commit }) {
    state.showClosedSprints = !state.showClosedSprints;
    if (state.showClosedSprints) {
      http.get(`api/project-sprints?project_id=${state.project.id}&status=closed&relations=false`).then((response) => {
        commit('storeSprints', response.data);
      });
    } else {
      commit('removeClosedSprints');
    }
  },

  loadSprintUserStories({ state, commit }, payload) {
    http.get(`api/project-sprints/${payload.sprintID}`).then((response) => {
      commit('loadSprintUserStories', {
        sprint: response.data.data,
        index: payload.sprintIndex,
      });
    });
  },

  newSprint({ state, commit }) {
    const { length } = state.project.sprints;
    let startDate;
    let endDate;
    if (length > 0 && state.project.sprints[length - 1].deadline) {
      startDate = addDays(new Date(state.project.sprints[length - 1].deadline), 1);
      endDate = addDays(new Date(state.project.sprints[length - 1].deadline), 14);
    } else {
      startDate = new Date();
      endDate = addDays(new Date(), 13);
    }

    const newItem = {
      project_id: state.project.id,
      status: 'started',
      contract_status: 'irrelevant',
      advance_payment_status: 'irrelevant',
      final_payment_status: 'irrelevant',
      starts_at: format(startDate, 'yyyy-MM-dd'),
      deadline: format(endDate, 'yyyy-MM-dd'),
    };

    commit('openSprintForm', {
      item: newItem,
      index: -1,
    });
  },

  editSprint({ commit }, payload) {
    commit('openSprintForm', payload);
  },

  createSprint({ state, commit }, payload) {
    commit('createSprint', payload);
  },

  updateSprint({ commit }, sprint) {
    commit('updateSprint', sprint);
  },

  deleteSprint({ commit }, index) {
    commit('deleteSprint', index);
  },
};
