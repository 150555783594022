import Vue from 'vue';

export default {
  updateUserStories(state, payload) {
    if (payload.sprintIndex || payload.sprintIndex === 0) {
      const sprintID = state.project.sprints[payload.sprintIndex].id;
      const itemCount = payload.items.length;
      for (let i = 0; i < itemCount; i++) {
        payload.items[i].sprint_id = sprintID;
      }
      state.project.sprints[payload.sprintIndex].user_stories = payload.items;
    } else {
      const itemCount = payload.items.length;
      for (let i = 0; i < itemCount; i++) {
        payload.items[i].sprint_id = null;
      }
      state.project.user_stories_not_assigned = payload.items;
    }
  },

  bulkInsertUserStories(state, userStories) {
    const sprintID = +userStories[0].sprint_id;
    if (userStories[0].sprint_id) {
      const sprintCount = state.project.sprints.length;
      for (let i = 0; i < sprintCount; i++) {
        if (state.project.sprints[i].id === sprintID) {
          state.project.sprints[i].user_stories = state.project.sprints[i].user_stories.concat(userStories);
          break;
        }
      }
    } else {
      state.project.user_stories_not_assigned = state.project.user_stories_not_assigned.concat(userStories);
    }
    state.userStoryDialog = false;
  },

  openUserStoryForm(state, payload) {
    state.editedItem = JSON.parse(JSON.stringify(payload.item));
    if (state.editedItem.sprint_id !== null) {
      state.editedItem.sprint_id = +state.editedItem.sprint_id;
    }
    state.defaultItem = JSON.parse(JSON.stringify(payload.item));
    state.editedIndex = payload.index;
    state.sprintIndex = payload.sprintIndex;
    state.userStoryDialog = !state.userStoryDialog;
    // window.location.hash = 'modal';
  },

  openBulkInsertForm(state, payload) {
    state.editedItem = JSON.parse(JSON.stringify(payload.item));
    if (state.editedItem.sprint_id !== null) {
      state.editedItem.sprint_id = +state.editedItem.sprint_id;
    }
    state.defaultItem = JSON.parse(JSON.stringify(payload.item));
    state.editedIndex = payload.index;
    state.sprintIndex = payload.sprintIndex;
    state.bulkInsertDialog = !state.bulkInsertDialog;
    // window.location.hash = 'modal';
  },

  createUserStory(state, userStory) {
    if (userStory.sprint_id) {
      const sprintCount = state.project.sprints.length;
      for (let i = 0; i < sprintCount; i++) {
        if (state.project.sprints[i].id === userStory.sprint_id) {
          state.project.sprints[i].user_stories.push(userStory);
          break;
        }
      }
    } else {
      state.project.user_stories_not_assigned.unshift(userStory);
    }
    state.userStoryDialog = false;
  },

  updateUserStory(state, userStory) {
    if (state.sprintIndex || state.sprintIndex === 0) {
      state.project.sprints[state.sprintIndex].user_stories.splice(state.editedIndex, 1, userStory);
    } else {
      state.project.user_stories_not_assigned.splice(state.editedIndex, 1, userStory);
    }
    state.userStoryDialog = false;
  },

  updateUserStoryDescription(state, description) {
    if (state.sprintIndex || state.sprintIndex === 0) {
      Vue.set(state.project.sprints[state.sprintIndex].user_stories[state.editedIndex], 'info', description);
    } else {
      Vue.set(state.project.user_stories_not_assigned[state.editedIndex], 'info', description);
    }
  },

  updateUserStoryStatus(state, userStory) {
    if (state.sprintIndex || state.sprintIndex === 0) {
      state.project.sprints[state.sprintIndex].user_stories.splice(state.editedIndex, 1, userStory);
    } else {
      state.project.user_stories_not_assigned.splice(state.editedIndex, 1, userStory);
    }
  },

  addUserStoryAttachments(state, userStory) {
    if (userStory.sprint_id !== null) {
      let sprintIndex = null;
      const sprintCount = state.project.sprints.length;
      for (let i = 0; i < sprintCount; i++) {
        if (state.project.sprints[i].id === userStory.sprint_id) {
          sprintIndex = i;
        }
      }
      if (sprintIndex !== null) {
        const count = state.project.sprints[sprintIndex].user_stories.length;
        for (let i = 0; i < count; i++) {
          if (state.project.sprints[sprintIndex].user_stories[i].id === userStory.id) {
            state.project.sprints[sprintIndex].user_stories.splice(i, 1, userStory);
          }
        }
      }
    } else {
      const count = state.project.user_stories_not_assigned.length;
      for (let i = 0; i < count; i++) {
        if (state.project.user_stories_not_assigned[i].id === userStory.id) {
          state.project.user_stories_not_assigned.splice(i, 1, userStory);
        }
      }
    }
  },

  toggleUserStoryStatus(state, payload) {
    if (payload.sprintIndex || payload.sprintIndex === 0) {
      state.project.sprints[payload.sprintIndex].user_stories.splice(payload.index, 1, payload.item);
    } else {
      state.project.user_stories_not_assigned.splice(payload.index, 1, payload.item);
    }
  },

  deleteUserStory(state, payload) {
    if (payload.sprintIndex || payload.sprintIndex === 0) {
      state.project.sprints[payload.sprintIndex].user_stories.splice(payload.index, 1);
    } else {
      state.project.user_stories_not_assigned.splice(payload.index, 1);
    }
  },

  moveUserStory(state, userStory) {
    if ((state.sprintIndex || state.sprintIndex === 0) && !userStory.sprint_id) {
      state.project.sprints[state.sprintIndex].user_stories.splice(state.editedIndex, 1);
      state.project.user_stories_not_assigned.push(userStory);
    } else if ((state.sprintIndex || state.sprintIndex === 0) && userStory.sprint_id) {
      state.project.sprints[state.sprintIndex].user_stories.splice(state.editedIndex, 1);
      const sprintCount = state.project.sprints.length;
      for (let i = 0; i < sprintCount; i++) {
        if (state.project.sprints[i].id === userStory.sprint_id) {
          state.project.sprints[i].user_stories.push(userStory);
          break;
        }
      }
    } else {
      state.project.user_stories_not_assigned.splice(state.editedIndex, 1);
      const sprintCount = state.project.sprints.length;
      for (let i = 0; i < sprintCount; i++) {
        if (state.project.sprints[i].id === userStory.sprint_id) {
          state.project.sprints[i].user_stories.push(userStory);
          break;
        }
      }
    }
  },

  moveSelectedUserStories(state, payload) {
    const projectID = +payload[0].project_id;
    const sprintID = +payload[0].sprint_id;
    let sprintIndex = null;
    if (sprintID) {
      sprintIndex = state.project.sprints.findIndex(sprint => sprint.id === sprintID);
    }
    Object.values(state.selectedUserStories).forEach((story) => {
      if (story.sprintIndex !== null) {
        state.project.sprints[story.sprintIndex].user_stories.splice(story.storyIndex, 1);
      } else {
        state.project.user_stories_not_assigned.splice(story.storyIndex, 1);
      }
    });

    if (projectID === state.project.id) {
      if (sprintIndex !== null) {
        state.project.sprints[sprintIndex].user_stories = [...state.project.sprints[sprintIndex].user_stories, ...payload];
      } else {
        state.project.user_stories_not_assigned = [...state.project.user_stories_not_assigned, ...payload];
      }
    }
  },

  selectUserStory(state, payload) {
    if (state.selectedUserStories[payload.id]) {
      Vue.set(state.selectedUserStories, payload.id, null);
      delete state.selectedUserStories[payload.id];
    } else {
      Vue.set(state.selectedUserStories, payload.id, {
        sprintIndex: payload.sprintIndex,
        storyIndex: payload.storyIndex,
      });
    }
  },

  clearSelectedUserStories(state) {
    state.selectedUserStories = {};
  },
};
