export default {
  storeSprints(state, sprints) {
    for (const sprint of sprints) {
      sprint.notLoaded = true;
      sprint.user_stories = [];
    }
    state.project.sprints = [...state.project.sprints, ...sprints];
  },

  removeClosedSprints(state) {
    for (let i = 0; i < state.project.sprints.length; i++) {
      if (state.project.sprints[i].status === 'closed') {
        state.project.sprints.splice(i, 1);
        i--;
      }
    }
  },

  loadSprintUserStories(state, payload) {
    payload.sprint.notLoaded = false;
    state.project.sprints.splice(payload.index, 1, payload.sprint);
  },

  openSprintForm(state, payload) {
    state.editedItem = JSON.parse(JSON.stringify(payload.item));
    state.editedIndex = payload.index;
    state.sprintDialog = !state.sprintDialog;
    // window.location.hash = 'modal';
  },

  createSprint(state, sprint) {
    if ((sprint.status !== 'closed' || state.showClosedSprints) && (sprint.status !== 'supported' || state.showSupportedSprints)) {
      state.project.sprints.push(sprint);
    }
    state.sprintDialog = false;
  },

  updateSprint(state, sprint) {
    state.sprintDialog = false;
    const sprintIndex = state.project.sprints.findIndex(item => item.id === sprint.id);
    if (sprintIndex === -1) {
      return;
    }
    if ((sprint.status !== 'closed' || state.showClosedSprints)
      && (sprint.status !== 'supported' || state.showSupportedSprints)) {
      state.project.sprints.splice(sprintIndex, 1, sprint);
    } else {
      state.project.sprints.splice(sprintIndex, 1);
    }
  },

  deleteSprint(state, index) {
    state.project.sprints.splice(index, 1);
  },
};
