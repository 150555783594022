<template>
  <div></div>
</template>

<script>
export default {
  name: 'Home',

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      const redirectTo = vm.$store.getters.permissions['inventory.items.view']
        ? '/assets'
        : '/profile';
      vm.$router.replace(redirectTo);
    });
  },
};
</script>

<style scoped>

</style>
