<template>
  <v-dialog
    v-model="isOpen"
    max-width="400"
    transition="slide-y-reverse-transition"
    persistent
  >
    <v-card>
      <v-card-title style="word-break: unset">
        {{ title }}
      </v-card-title>
      <v-card-text>
        {{ body }}
      </v-card-text>
      <v-card-actions>
        <v-spacer/>
        <v-btn
          color="primary"
          text
          @click.native="onCancel"
        >
          {{ cancelText }}
        </v-btn>
        <v-btn
          ref="confirmBtn"
          color="primary"
          text
          @click.native="onConfirm"
        >
          {{ confirmText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import eventBus, { CONFIRM_DIALOG_CLOSED, OPEN_CONFIRM_DIALOG } from '../util/event-bus';

export default {
  name: 'ConfirmDialog',

  data() {
    return {
      isOpen: false,
      title: '',
      body: '',
      cancelText: '',
      confirmText: '',
    };
  },

  created() {
    eventBus.$on(OPEN_CONFIRM_DIALOG, async (options) => {
      if (typeof options === 'string') {
        this.title = options;
        this.body = '';
        this.cancelText = this.$t('cancel');
        this.confirmText = this.$t('confirm');
      } else {
        this.title = options.title ? options.title : this.$t('are_you_sure');
        this.body = options.body ? options.body : '';
        this.cancelText = options.cancelText ? options.cancelText : this.$t('cancel');
        this.confirmText = options.confirmText ? options.confirmText : this.$t('confirm');
      }
      this.isOpen = true;
      await this.$nextTick();
      this.$refs.confirmBtn.$el.focus();
    });
  },

  methods: {
    onConfirm() {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, true);
      this.isOpen = false;
    },

    onCancel() {
      eventBus.$emit(CONFIRM_DIALOG_CLOSED, false);
      this.isOpen = false;
    },
  },
};
</script>
