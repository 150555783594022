<template>
  <v-navigation-drawer
    v-model="$store.state.settings.sidenavOpen"
    height="100%"
    app
    clipped
  >
    <v-list
      dense
      nav
    >
      <template v-for="(item, i) in displayedItems">
        <v-subheader
          v-if="item.heading"
          :key="i"
          class="text-subtitle-2"
        >
          {{ item.heading }}
        </v-subheader>

        <v-divider
          v-else-if="item.divider"
          :key="i"
          class="my-2"
        />

        <v-list-item
          v-else
          :key="i"
          :disabled="!item.url"
          :to="item.url"
          active-class="primary--text"
          link
          @click="onNavigationItemClick"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.text) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item
        link
        @click="logout"
      >
        <v-list-item-icon>
          <v-icon>mdi-logout</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <div class="d-flex align-center justify-center py-4 border-t">
        <span class="mr-2 text-subtitle-2">
          {{ $t('developed_by') }}:
        </span>
        <a
          :href="$t('evolco_url')"
          class="d-flex"
          target="_blank"
        >
          <img src="../assets/evolco-logo-dark.png" alt="" height="20">
        </a>
      </div>
    </template>
  </v-navigation-drawer>
</template>

<script>
import { LOGOUT } from '../store/action-types';

export default {
  data() {
    return {
      items: [
        {
          icon: 'mdi-wrench', text: 'assets', url: '/assets', requiredPermissions: ['inventory.items.view'],
        },
        {
          icon: 'mdi-map-marker', text: 'check_ins', url: '/check-ins', requiredPermissions: ['check-in.*'],
        },
        {
          icon: 'mdi-office-building', text: 'projects.general.projects', url: '/projects', requiredPermissions: ['projects.view.*'],
        },
        {
          icon: 'mdi-clock-outline', text: 'time_tracking.general.page_title', url: '/time-tracking', requiredPermissions: ['projects.view.*'],
        },
        {
          icon: 'mdi-account-multiple', text: 'users', url: '/users', allowedRoles: ['admin'],
        },
        { divider: true },
        { icon: 'mdi-account', text: 'my_profile', url: '/profile' },
      ],
    };
  },

  computed: {
    displayedItems() {
      return this.items.filter((i) => {
        if (i.allowedRoles) {
          return i.allowedRoles.indexOf(this.currentUser.role) > -1;
        }
        if (i.requiredPermissions) {
          return this.$can(i.requiredPermissions);
        }
        return true;
      });
    },

    currentUser() {
      return this.$store.getters.currentUser;
    },
  },

  methods: {
    logout() {
      this.$store.dispatch(LOGOUT);
    },

    onNavigationItemClick() {
      if (this.$vuetify.breakpoint.mdAndDown) {
        this.$store.state.settings.sidenavOpen = false;
      }
    },
  },
};
</script>
