import Vue from 'vue';

const eventBus = new Vue();

// event types
export const OPEN_CONFIRM_DIALOG = 'open-confirm-dialog';
export const CONFIRM_DIALOG_CLOSED = 'confirm-dialog-closed';
export const OPEN_SNACKBAR = 'open-snackbar';
export const SHOW_LOADER = 'show-loader';
export const HIDE_LOADER = 'hide-loader';

export function onConfirm(confirmDialogOptions) {
  eventBus.$emit(OPEN_CONFIRM_DIALOG, confirmDialogOptions);
  return new Promise((resolve, reject) => {
    eventBus.$on(CONFIRM_DIALOG_CLOSED, async (confirmed) => {
      if (confirmed) {
        resolve();
      } else {
        reject();
      }
      eventBus.$off(CONFIRM_DIALOG_CLOSED);
    });
  });
}

export default eventBus;
