import http from '../../../../api/http';

export default {
  newAssignee({ commit }) {
    commit('openAssigneeForm');
  },

  editAssignee({ commit }, payload) {
    commit('openAssigneeForm', payload);
  },

  createAssignee({ state, commit }, payload) {
    commit('createAssignee', payload);
  },

  getProjectAssignees({ commit }, projectID) {
    return http.get(`api/project-assignees?project_id=${projectID}`).then((res) => {
      commit('storeProjectAssignees', res.data.data);
    });
  },

  updateAssignees({ commit }, assignees) {
    commit('updateAssignees', assignees);
  },
};
