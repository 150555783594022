import sprintActions from './sprints/actions';
import userStoryActions from './user-stories/actions';
import taskActions from './tasks/actions';
import assigneeActions from './assignees/actions';

import sprintMutations from './sprints/mutations';
import userStoryMutations from './user-stories/mutations';
import taskMutations from './tasks/mutations';
import assigneeMutations from './assignees/mutations';
import http from '../../../api/http';
import i18n from '../../../i18n/i18n-config';

const state = {
  project: {},
  projectPermission: '',
  sprintIndex: null,
  userStoryIndex: null,
  projectEditDialog: false,
  userStoryDialog: false,
  bulkInsertDialog: false,
  sprintDialog: false,
  taskDialog: false,
  assigneeDialog: false,
  userStoryCopyDialog: false,
  userStoryMoveDialog: false,
  selectingUserStories: false,
  showStartedSprints: true,
  showSupportedSprints: false,
  showClosedSprints: false,
  selectedUserStories: {},
  defaultItem: {},
  editedItem: {},
  editedIndex: -1,
  editedTask: {},
  editedTaskIndex: -1,
  isLoaderDisplayed: false,
  userStoryStatuses: [
    { value: 'new', text: i18n.t('projects.user_story_status.not_started'), color: 'grey lighten-4' },
    { value: 'in_progress', text: i18n.t('projects.user_story_status.in_progress'), color: 'blue lighten-3' },
    { value: 'done', text: i18n.t('projects.user_story_status.done'), color: 'green accent-2' },
  ],
};

const getters = {
  project(state) {
    return state.project;
  },

  projectPermission(state) {
    return state.projectPermission;
  },

  assignees(state) {
    return state.project.assignees;
  },

  editedItem(state) {
    return state.editedItem;
  },

  editedIndex(state) {
    return state.editedIndex;
  },

  selectedUserStories(state) {
    return state.selectedUserStories;
  },
};

const mutations = {
  storeProject(state, project) {
    state.selectedUserStories = {};
    state.selectingUserStories = false;
    state.projectEditDialog = false;
    state.showClosedSprints = false;
    state.showSupportedSprints = false;
    state.project = project;
    let currentUser = localStorage.getItem('currentUser');
    if (currentUser) {
      currentUser = JSON.parse(currentUser);
      if (currentUser.role === 'admin') {
        state.projectPermission = 'creator';
      } else if (project.assignees) {
        project.assignees.forEach((assignee) => {
          if (assignee.user_id === currentUser.id) {
            state.projectPermission = assignee.permission;
          }
        });
      } else {
        state.projectPermission = '';
      }
    } else {
      state.projectPermission = '';
    }
  },

  setProjectPermission(state, val) {
    state.projectPermission = val;
  },

  openProjectEditForm(state) {
    state.editedItem = JSON.parse(JSON.stringify(state.project));
    state.projectEditDialog = true;
    // window.location.hash = 'modal';
  },

  setIsLoaderDisplayed(state, value) {
    state.isLoaderDisplayed = value;
  },

  ...sprintMutations,
  ...userStoryMutations,
  ...taskMutations,
  ...assigneeMutations,
};


const actions = {
  getProject({ commit, dispatch }, id) {
    http.get(`api/projects/${id}`).then((response) => {
      commit('storeProject', response.data);
      dispatch('hideTopProgress');
    });
  },

  showTopProgress({ commit }) {
    commit('setIsLoaderDisplayed', true);
  },

  hideTopProgress({ commit }) {
    commit('setIsLoaderDisplayed', false);
  },

  ...sprintActions,
  ...userStoryActions,
  ...taskActions,
  ...assigneeActions,
};

export default {
  state,
  getters,
  mutations,
  actions,
};
