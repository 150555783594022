/* eslint-disable no-param-reassign,import/no-cycle */
import axios from 'axios';
import store from '../store';
import i18n from '../i18n/i18n-config';
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';
import { LOGOUT } from '../store/action-types';

const http = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
});

http.interceptors.request.use((config) => {
  if (store.getters.accessToken) {
    config.headers.common.Authorization = `Bearer ${store.getters.accessToken}`;
  }
  if (store.getters.impersonatingAs) {
    config.headers.common['Impersonating-As'] = `${store.getters.impersonatingAs}`;
  }
  config.headers.common.Locale = store.state.settings.locale;
  // console.log(JSON.stringify(config.data, null, 2));
  return config;
});

http.interceptors.response.use(res => res, (error) => {
  if (!error?.response?.config) {
    eventBus.$emit(OPEN_SNACKBAR, i18n.t('network_error'));
    throw error;
  }
  if (error.response && error.response.status) {
    switch (error.response.status) {
      case 500: {
        if (error.response.data.message && error.response.data.message.indexOf('login') > -1) {
          store.dispatch(LOGOUT).catch(() => {});
        } else {
          eventBus.$emit(OPEN_SNACKBAR, i18n.t('http_errors.500'));
        }
        throw error;
      }
      default: {
        /**
         *  List of error messages that have error messages written for them.
         *  To add another response code:
         *    1) add translations messages to src/i18n/{locale}/error-messages
         *    2) include the error code in showErrorMessagesFor array
         *  To add custom handling for a given error, see the above examples for 401 and 500
         */
        const showErrorMessagesFor = [403, 422, 429];
        if (showErrorMessagesFor.indexOf(error.response.status) > -1) {
          eventBus.$emit(OPEN_SNACKBAR, i18n.t(`http_errors.${error.response.status}`));
        }
        throw error;
      }
    }
  } else if (typeof error === 'string') {
    eventBus.$emit(OPEN_SNACKBAR, error);
    throw error;
  }
});

export default http;
