import Vue from 'vue';
import * as Sentry from '@sentry/browser';
import * as Integrations from '@sentry/integrations';
import './registerServiceWorker';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import registerHelpers from './util/helpers';
import registerFilters from './util/filters';
import i18n from './i18n/i18n-config';
import App from './App.vue';
import addProjectsModuleDependencies from './components/projects/vue-prototype-decorations';

addProjectsModuleDependencies();

// for moved projects module. New http calls should live in services inside api folder
Vue.config.productionTip = false;
Vue.prototype.$can = (permissions) => {
  if (!Vue.prototype.$isAdmin()) {
    if (!permissions || !store.getters.permissions) {
      return false;
    }
    for (let i = 0; i < permissions.length; i++) {
      if (store.getters.permissions[permissions[i]]) {
        return true;
      }
    }
    return false;
  }
  return true;
};

registerFilters(Vue);
registerHelpers(Vue);

if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_SENTRY_KEY) {
  Sentry.init({
    dsn: process.env.VUE_APP_SENTRY_KEY,
    integrations: [new Integrations.Vue({ Vue, attachProps: false })],
    ignoreErrors: [
      'Unauthenticated',
      'NavigationDuplicated',
      'Non-Error promise rejection',
      'Network Error',
      'Request aborted',
      'timeout of 0ms exceeded',
      /Redirected when going from/,
      /401/,
      /422/,
      /Navigation cancelled from/,
    ],
  });
}

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app');
