/* eslint-disable object-curly-spacing,no-prototype-builtins */
import Vue from 'vue';
import Vuex from 'vuex';
import auth from './modules/auth';
import settings from './modules/settings';
import projects from './modules/deprecated/projects';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},

  getters: {},

  mutations: {},

  actions: {},

  modules: {
    auth,
    settings,
    projects,
  },
});
