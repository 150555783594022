export default {
  openTaskForm(state, payload) {
    state.editedTask = JSON.parse(JSON.stringify(payload.item));
    state.editedTaskIndex = payload.index;
    state.userStoryIndex = payload.userStoryIndex;
    state.taskDialog = !state.taskDialog;
    // window.location.hash += '#another-modal';
  },

  createTask(state, task) {
    if (state.sprintIndex || state.sprintIndex === 0) {
      state.project.sprints[state.sprintIndex].user_stories[state.userStoryIndex].tasks.push(task);
    } else {
      state.project.user_stories_not_assigned[state.userStoryIndex].tasks.push(task);
    }
    state.editedItem.tasks.push(task);
    state.taskDialog = false;
  },

  updateTask(state, task) {
    if (state.sprintIndex || state.sprintIndex === 0) {
      state.project.sprints[state.sprintIndex].user_stories[state.userStoryIndex].tasks.splice(state.editedTaskIndex, 1, task);
    } else {
      state.project.user_stories_not_assigned[state.userStoryIndex].tasks.splice(state.editedTaskIndex, 1, task);
    }
    state.editedItem.tasks.splice(state.editedTaskIndex, 1, task);
    state.taskDialog = false;
  },

  updateTaskStatus(state, payload) {
    if (payload.sprintIndex || payload.sprintIndex === 0) {
      state.project.sprints[payload.sprintIndex].user_stories[payload.userStoryIndex].tasks.splice(payload.index, 1, payload.item);
    } else {
      state.project.user_stories_not_assigned[payload.userStoryIndex].tasks.splice(payload.index, 1, payload.item);
    }
    state.editedItem.tasks.splice(payload.index, 1, payload.item);
  },

  deleteTask(state, payload) {
    if (payload.sprintIndex || payload.sprintIndex === 0) {
      state.project.sprints[payload.sprintIndex].user_stories[payload.userStoryIndex].tasks.splice(payload.index, 1);
    } else {
      state.project.user_stories_not_assigned[payload.userStoryIndex].tasks.splice(payload.index, 1);
    }
    state.editedItem.tasks.splice(payload.index, 1);
  },
};
