<template>
  <v-snackbar
    v-model="isOpen"
    :timeout="timeout"
    bottom
  >
    <div>
      <v-progress-circular
        v-if="showProgress"
        class="mr-3"
        size="24"
        width="3"
        indeterminate
      />
      {{ text }}
    </div>
    <v-btn
      v-if="action"
      color="primary"
      text
      @click.stop.prevent="onSnackbarActionClick"
    >
      {{ action }}
    </v-btn>
  </v-snackbar>
</template>

<script>
import eventBus, { OPEN_SNACKBAR } from '../util/event-bus';

export default {
  name: 'Snackbar',

  data() {
    return {
      text: '',
      url: '',
      action: '',
      timeout: 4000,
      isOpen: false,
      showProgress: false,
    };
  },

  created() {
    eventBus.$on(OPEN_SNACKBAR, async (options) => {
      this.isOpen = false;
      await this.$nextTick();
      if (typeof options === 'string') {
        this.text = options;
        this.action = '';
        this.url = '';
        this.timeout = 4000;
        this.showProgress = false;
        this.isOpen = true;
      } else {
        this.text = options.text ? options.text : '';
        this.action = options.action ? options.action : '';
        this.url = options.url ? options.url : '';
        this.timeout = options.timeout ? options.timeout : 4000;
        this.showProgress = !!options.showProgress;
        this.isOpen = true;
      }
    });
  },

  methods: {
    onSnackbarActionClick() {
      if (this.url) {
        this.$router.push(this.url);
      }
      this.isOpen = false;
    },
  },
};
</script>
