import Vue from 'vue';

export default {
  storeProjectAssignees(state, payload) {
    Vue.set(state.project, 'assignees', payload);
  },

  openAssigneeForm(state) {
    state.assigneeDialog = !state.assigneeDialog;
    // window.location.hash = 'modal';
  },

  createAssignee(state, assignee) {
    state.assignees.push(assignee);
    state.assigneeDialog = false;
  },

  updateAssignees(state, assignees) {
    state.project.assignees = assignees;
    state.assigneeDialog = false;
  },
};
